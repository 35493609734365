<template>
  <div>
    <van-nav-bar
      :title="company"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="worthtest">
      <!-- 101题 -->
      <div
        class="paading-min"
        v-for="(item, index) in subjectList"
        :key="index"
        v-if="num == index && num !== subjectList.length"
      >
        <div>{{ num + 1 }} / {{ subjectList.length + 3 }}</div>
        <div class="xhtm">
          <div class="timu">{{ item.subject }}</div>
          <div
            class="xuanxiangcenter"
            v-for="(option, optionIndex) in item.item"
            :key="optionIndex"
          >
            <div
              class="xuanxiang"
              :class="{ xuanxiangactive: option.active }"
              @click="handleOptionClick(item, option)"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 102题 -->
      <div class="paading-min" v-if="num == 101">
        <div>102 / {{ subjectList.length + 3 }}</div>
        <div class="xhtm">
          <div class="timu">
            您对{{company}}的满意度是多少？（1分-很不满意，5分-非常满意）
          </div>
          <div class="myd"></div>
          <div class="fs">
            <div>不满意</div>

            <van-rate
              v-model="fs"
              void-color="#B9B8B8"
              color="#72ACF4"
              size="26"
            />
            <div>非常满意</div>
          </div>
        </div>
      </div>

      <!-- 103题 -->
      <div class="paading-min" v-if="num == 102">
        <div>103 / 104</div>
        <div class="xhtm">
          <div class="timu">
            如果以后还有类似的{{company}}活动，您有多大意愿参加？（1分-很不愿意，5分-非常愿意）
          </div>
          <div class="fs">
            <div>不满意</div>

            <van-rate
              v-model="fs2"
              void-color="#B9B8B8"
              color="#72ACF4"
              size="26"
            />
            <div>非常满意</div>
          </div>
        </div>
      </div>

      <!-- 104题 -->
      <div class="paading-min" v-if="num === 103">
        <div>104 / 104</div>
        <div class="xhtm">
          <div class="timu">您对以下哪方面的训练内容更有兴趣？（可多选）</div>
          <div class="xuanxiangcenter">
            <div
              class="xuanxiang"
              :class="{
                xuanxiangactive: selectedOptions.includes('睡眠相关主题的练习'),
              }"
              @click="toggleOption('睡眠相关主题的练习')"
            >
              睡眠相关主题的练习
            </div>
            <div
              class="xuanxiang"
              :class="{
                xuanxiangactive:
                  selectedOptions.includes('专注力相关主题的练习'),
              }"
              @click="toggleOption('专注力相关主题的练习')"
            >
              专注力相关主题的练习
            </div>
            <div
              class="xuanxiang"
              :class="{
                xuanxiangactive:
                  selectedOptions.includes('亲子沟通相关主题的练习'),
              }"
              @click="toggleOption('亲子沟通相关主题的练习')"
            >
              亲子沟通相关主题的练习
            </div>
            <div
              class="xuanxiang"
              :class="{
                xuanxiangactive: selectedOptions.includes('正念减压相关练习'),
              }"
              @click="toggleOption('正念减压相关练习')"
            >
              正念减压相关练习
            </div>
            <div
              class="xuanxiang"
              :class="{
                xuanxiangactive: selectedOptions.includes('运动健康相关练习'),
              }"
              @click="toggleOption('运动健康相关练习')"
            >
              运动健康相关练习
            </div>
          </div>
        </div>
        <div class="qita">
          <div class="qitatitle">其他</div>
          <textarea
            v-model="qita"
            class="textarea"
            placeholder="请输入您的提议请输入您的提议请输入您的提议请输入您的提议"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
      </div>

      <div class="btn-center">
        <button class="shangti" v-if="num > 0" @click="uptimu">上一题</button>
        <button class="shangti" v-if="num == 101 && fs" @click="gotimu">
          下一题
        </button>
        <button class="shangti" v-if="num == 102 && fs2" @click="gotimu">
          下一题
        </button>
      </div>

      <div class="tanchuang" v-show="showtanchuang">
        <div class="tanchuanghezi">
          <div>已完成测评</div>
          <div class="zhu">注：报告不可回看，请截图保存</div>
          <button @click="totestresult">查看结果</button>
        </div>
      </div>
      <button class="tijiao" v-if="num == 103" @click="submit">提交</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { startAssess, assessSave, setComplated } from "../../api/login";
export default {
  data() {
    return {
      showbtn: false,
      showtanchuang: false,
      subjectList: [],
      num: 0,
      daan: [],
      id: "",
      reportId: "",
      subjectId: "",
      fs: 0,
      fs2: 0,
      selectedOptions: [],
      qita: "",
      unitId: window.localStorage.getItem("unitId"), //公司id
      company: "", //公司
    };
  },
  created() {},
  mounted() {
    this.getstartAssess();
    if (this.unitId == "undefined") {
      this.company = "高情商训练营";
    }
    if (this.unitId == "sys1818") {
      this.company = "情绪价值训练营";
    }
    if (this.unitId == "1397") {
      this.company = "高情商训练营";
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gotimu() {
      this.num++;
    },
    getstartAssess() {
      let data = {
        userId: 1,
      };

      //获取题目
      startAssess(data).then((res) => {
        console.log(res.data.subjectList);
        this.subjectList = res.data.subjectList;
        this.reportId = res.data.reportId;
      });
    },
    handleOptionClick(question, clickedOption) {
      question.item.forEach((option) => {
        option.active = false;
      });

      clickedOption.active = true;

      console.log(clickedOption.id);
      this.daan[this.num] =
        "lb" +
        clickedOption.lbassessment_id +
        "-" +
        clickedOption.subjectid +
        "=" +
        clickedOption.id;

      this.num++;
      this.num--;
      setTimeout(() => {
        this.num++;
      }, 100);

      // if (this.num < this.subjectList.length - 1) {
      //   console.log(this.daan);
      //   this.num++;
      //   this.num--;
      //   setTimeout(() => {
      //     this.num++;
      //   }, 100);
      // } else {
      //   this.showbtn = true;
      //   this.num++;
      //   setTimeout(() => {
      //     this.num--;
      //   }, 10);
      // }
    },
    uptimu() {
      this.num--;
    },
    submit() {
      Toast.loading({ message: "提交中", forbidClick: true, duration: 0 });
      console.log(this.daan);
      // 把补充其他内容加入selectedOptions
      if (this.qita) {
        this.selectedOptions.push(this.qita);
      }
      let data = {
        userId: 1,
        answer: this.daan,
        subid: 1,
        answer2: this.fs + "分",
        answer3: this.fs2 + "分",
        answer4: this.selectedOptions,
        aftertest: "aftertest",
      };
      assessSave(data)
        .then((res) => {
          Toast.clear();
          this.showtanchuang = true;
          this.subjectId = res.data.subjectId;
        })
        .catch((error) => {
          Toast("服务器异常");
        });
    },

    totestresult() {
      console.log("this.$route.query.subjectId", this.$route.query.subjectId);
      this.$router.push({
        path: "/result",
        query: { subjectId: this.subjectId },
      });
    },
    toggleOption(option) {
      const index = this.selectedOptions.indexOf(option);
      if (index === -1) {
        // 如果选项不在数组中，添加它
        this.selectedOptions.push(option);
      } else {
        // 如果选项已经在数组中，移除它
        this.selectedOptions.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="less">
.worthtest {
  background-color: #f4fafe;
  min-height: 100vh;
  box-sizing: border-box;
  //   padding: 20px;
  .paading-min {
    padding: 20px;
  }
  .timu {
    font-size: 13px;
    font-weight: 500;
    color: #000025;
    line-height: 18px;
    letter-spacing: 1px;
    margin-top: 20px;
  }
  .xuanxiangcenter {
    .xuanxiang {
      margin: 0 auto;
      margin-top: 32px;
      width: 238px;
      // height: 40px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
      border-radius: 8px;
      font-size: 13px;
      font-weight: 500;
      color: #000025;
      // line-height: 40px;
      letter-spacing: 1px;
      // box-sizing: border-box;
      padding: 10px 15px;
    }
    .xuanxiangactive {
      background-color: #e4effb;
      border: 1px solid #72acf4;
      color: #72acf4;
    }
  }
  .btn-center {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;
    gap: 40px;
  }
  .shangti {
    border-radius: 50%;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    width: 96px;
    height: 96px;
    border: 0;
    background-color: #fff;
  }

  .tanchuang {
    height: calc(100vh + 140px);
    width: 100%;
    top: 0;
    background: rgba(3, 3, 3, 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .tanchuanghezi {
      width: 248px;
      height: 222px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(171, 171, 171, 0.49),
        inset 1px 1px 0px 0px #ffffff;
      border-radius: 16px;
      backdrop-filter: blur(3px);
      box-sizing: border-box;
      padding: 40px;
      text-align: center;
      .zhu {
        margin-top: 20px;
        color: #bebcbc;
      }

      button {
        width: 164px;
        height: 36px;
        background: #72acf4;
        border-radius: 100px;
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        line-height: 21px;
        border: 0;
        margin-top: 20px;
      }
    }
  }
}
.qita {
  height: 93px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 20px 32px 0px 32px;
  .qitatitle {
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #f6f6f6;
  }

  textarea {
    font-size: 12px;
    font-weight: 300;
    color: #333;
    line-height: 17px;
    letter-spacing: 1px;
    height: 60%;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    border: 0;
    resize: none; /* 禁用调整大小功能 */
  }
}
.myd {
  display: flex;
  justify-content: space-between;
  height: 14px;
}
.navbar {
  background-color: #ededed;
}
.fs {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 300;
  color: #000025;
  line-height: 14px;
  letter-spacing: 1px;
  box-sizing: border-box;
  gap: 10px;
}
.van-nav-bar .van-icon {
  color: #464646;
}
.tijiao {
  width: 100%;
  height: 72px;
  border: 0;
  line-height: 72px;
  position: relative;
  bottom: 0;
  background-color: #72acf4;
  color: #fff;
}
</style>
